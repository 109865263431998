<script setup>
import WelcomeItem from './WelcomeItem.vue'
import DocumentationIcon from './icons/IconDocumentation.vue'
import EcosystemIcon from './icons/IconEcosystem.vue'
import {ref} from "vue";


let germanProcentages = [
  {
    score: 15,
    percentage: 0.98,
    percentageAsString: "98%"
  },
  {
    score: 14,
    percentage: 0.94,
    percentageAsString: "94%"
  },
  {
    score: 13,
    percentage: 0.90,
    percentageAsString: "90"
  },
  {
    score: 12,
    percentage: 0.85,
    percentageAsString: "85%"
  },
  {
    score: 11,
    percentage: 0.80,
    percentageAsString: "80%"
  },
  {
    score: 10,
    percentage: 0.75,
    percentageAsString: "75%"
  },
  {
    score: 9,
    percentage: 0.70,
    percentageAsString: "70%"
  },
  {
    score: 8,
    percentage: 0.65,
    percentageAsString: "65%"
  },
  {
    score: 7,
    percentage: 0.60,
    percentageAsString: "60%"
  },
  {
    score: 6,
    percentage: 0.53,
    percentageAsString: "53%"
  },
  {
    score: 5,
    percentage: 0.46,
    percentageAsString: "46%"
  },
  {
    score: 4,
    percentage: 0.40,
    percentageAsString: "40%"
  },
  {
    score: 3,
    percentage: 0.35,
    percentageAsString: "35%"
  },
  {
    score: 2,
    percentage: 0.30,
    percentageAsString: "30%"
  },
  {
    score: 1,
    percentage: 0.25,
    percentageAsString: "25%"
  },
]

let mathProcentages = [
  {
    score: 15,
    percentage: 0.99,
    percentageAsString: "99%"
  },
  {
    score: 14,
    percentage: 0.97,
    percentageAsString: "97%"
  },
  {
    score: 13,
    percentage: 0.95,
    percentageAsString: "95%"
  },
  {
    score: 12,
    percentage: 0.90,
    percentageAsString: "90%"
  },
  {
    score: 11,
    percentage: 0.85,
    percentageAsString: "85%"
  },
  {
    score: 10,
    percentage: 0.80,
    percentageAsString: "80%"
  },
  {
    score: 9,
    percentage: 0.75,
    percentageAsString: "75%"
  },
  {
    score: 8,
    percentage: 0.70,
    percentageAsString: "70%"
  },
  {
    score: 7,
    percentage: 0.65,
    percentageAsString: "65%"
  },
  {
    score: 6,
    percentage: 0.59,
    percentageAsString: "59%"
  },
  {
    score: 5,
    percentage: 0.52,
    percentageAsString: "52%"
  },
  {
    score: 4,
    percentage: 0.45,
    percentageAsString: "45%"
  },
  {
    score: 3,
    percentage: 0.39,
    percentageAsString: "39%"
  },
  {
    score: 2,
    percentage: 0.32,
    percentageAsString: "32%"
  },
  {
    score: 1,
    percentage: 0.25,
    percentageAsString: "25%"
  },
]


const germanScore = ref(0)
const mathScore = ref(0)
const ExcelJS = require('exceljs');
const FileSaver = require('file-saver');
const trigger = ref("");

const dataToExcel = async (data) => {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet('Notenstaffelung');
  sheet.columns = [
    { header: 'Note', key: 'score', width: 10 },
    { header: 'Prozent', key: 'percentage', width: 10 },
    { header: 'Punkte', key: 'points', width: 10 }
  ];
  data.forEach(item => {
    sheet.addRow({score: item.score, percentage: item.percentageAsString, points: item.byScore});
  });
  workbook.xlsx.writeBuffer()
      .then(buffer => FileSaver.saveAs(new Blob([buffer]), `Notenstaffelung_${trigger.value}_${Date.now()}.xlsx`))
      .catch(err => console.log('Error writing excel export', err));
}

const calcGerman = async () => {
    trigger.value = 'deutsch'
    germanProcentages = germanProcentages.map(item => Object.assign(item, {byScore: germanScore.value * item.percentage}))
    await dataToExcel(germanProcentages)
}

const calcMaths = async () => {
  trigger.value = 'mathematik'
  mathProcentages = mathProcentages.map(item => Object.assign(item, {byScore: mathScore.value * item.percentage}))
  await dataToExcel(mathProcentages)
}

</script>

<template>
  <WelcomeItem>
    <template #icon>
      <DocumentationIcon />
    </template>
    <template #heading>Lesekompetenz und Aufsätze</template>
    <div class="inputs">
    <input type="number" id="deutsch" placeholder="zu vergebende Punktzahl" v-model="germanScore">
    <button @click="calcGerman">Notenstaffelung berechnen</button>
    </div>
  </WelcomeItem>
  <WelcomeItem>
    <template #icon>
      <EcosystemIcon />
    </template>
    <template #heading>Mathematik</template>
    <div class="inputs">
      <input type="number" id="mathe" placeholder="zu vergebende Punktzahl" v-model="mathScore">
      <button @click="calcMaths">Notenstaffelung berechnen</button>
    </div>
  </WelcomeItem>
</template>

<style scoped>
.inputs {
  display: flex;
  flex-direction: column;
}

button  {
  margin-top: 10px;
  cursor: pointer;
  color: #fff;
  padding: 10px;
  background: #43C58F;
  border: none;
  border-radius: 25px;
}

button:hover {
  background: #369f74;
}
</style>
